import { useCallback, useEffect, useRef, useState } from 'react';

import { toast } from 'sonner';

import {
  GeneratingLoadingScopeClaims,
  LOADING_SCOPE_GENERATING,
} from '@ll-platform/frontend/creativeProposal/concept/consts';
import { AbortError } from '@ll-platform/frontend/features/llm/async/errors';
import { streamJson } from '@ll-platform/frontend/features/llmGenerators/helpers/streamJson';
import type { GenericLlmGeneratorResponseDto } from '@ll-platform/frontend/features/llmGenerators/types';
import type {
  ConceptAiOutput,
  CreativeProposalConcept,
} from '@ll-platform/frontend/src/creativeProposal/types';
import { useLoadingContext } from '@ll-platform/frontend/utils/contexts/LoadingContext';
import { waitForAsyncGeneratorReturn } from '@ll-platform/frontend/utils/helpers/async';
import { wait } from '@ll-platform/frontend/utils/helpers/helpers';
import { useUnmountOnce } from '@ll-platform/frontend/utils/hooks/useStrictModeEffect';
import { type DeepPartialObject } from '@ll-platform/frontend/utils/types/types';

export function useConceptGenerator({
  proposalId,
  initialConcept,
  onChange,
}: {
  proposalId: string;
  initialConcept: CreativeProposalConcept;
  onChange: (
    values: Partial<Pick<CreativeProposalConcept, 'title' | 'description'>>,
  ) => void;
}) {
  const loadingGeneratingCtx = useLoadingContext(
    LOADING_SCOPE_GENERATING,
    GeneratingLoadingScopeClaims.GenerateConcept.replace(
      ':id',
      initialConcept.id,
    ),
  );
  const [isHighlighted, setIsHighlighted] = useState(false);

  const abortControllerRef = useRef<AbortController | null>(null);
  const generate = useCallback(async () => {
    try {
      loadingGeneratingCtx.startLoadingClaim();
      abortControllerRef.current = new AbortController();
      const stream = streamJson<ConceptAiOutput>(
        {
          method: 'POST',
          endpoint: `/v1/creative-proposals/${proposalId}/concepts/${initialConcept.id}/generate`,
        },
        {},
        {
          abortSignal: abortControllerRef.current?.signal,
        },
      );

      let data:
        | DeepPartialObject<GenericLlmGeneratorResponseDto<ConceptAiOutput>>
        | undefined;
      for await (data of stream) {
        if (data?.output) {
          onChange(data.output);
        }
      }
      data = await waitForAsyncGeneratorReturn(stream, data);
      if (data?.output) {
        onChange(data.output);
      }
    } catch (error) {
      if (error instanceof AbortError) {
        return;
      }
      toast.error('Failed to generate concept');
      throw error;
    } finally {
      abortControllerRef.current = null;
      loadingGeneratingCtx.stopLoadingClaim();
      await wait(3000);
      setIsHighlighted(false);
    }
  }, [proposalId, initialConcept.id, loadingGeneratingCtx, onChange]);

  const cancelGenerate = useCallback(() => {
    try {
      if (!abortControllerRef.current) {
        return;
      }

      abortControllerRef.current.abort('User cancelled the operation');
      abortControllerRef.current = null;
    } catch (e) {
      console.warn(e);
    }
  }, []);

  const isAutogeneratedRef = useRef(false);
  useEffect(() => {
    if (
      !isAutogeneratedRef.current &&
      !initialConcept.title &&
      !initialConcept.description &&
      !initialConcept.moodboard.length
    ) {
      isAutogeneratedRef.current = true;
      generate();
      setIsHighlighted(true);
    }
  }, [initialConcept, generate]);

  useUnmountOnce(cancelGenerate);

  return {
    isHighlighted,
    isGenerating: loadingGeneratingCtx.isLoading,
  };
}
