import { useCallback, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Trash2 } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

import { cn } from '@ll-platform/frontend/components/shadcn/lib/utils';
import { Button } from '@ll-platform/frontend/components/shadcn/ui/button';
import {
  Card,
  CardContent,
} from '@ll-platform/frontend/components/shadcn/ui/card';
import {
  Form,
  FormField,
  FormItem,
  FormMessage,
} from '@ll-platform/frontend/components/shadcn/ui/form';
import { Input } from '@ll-platform/frontend/components/shadcn/ui/input';
import { TwConfirmDialog } from '@ll-platform/frontend/components/tw/TwConfirmDialog/TwConfirmDialog';
import { useTwConfirmDialog } from '@ll-platform/frontend/components/tw/TwConfirmDialog/useTwConfirmDialog';
import TwTextareaAutoresize from '@ll-platform/frontend/components/tw/TwTextareaAutoresize/TwTextareaAutoresize';
import { useConceptGenerator } from '@ll-platform/frontend/creativeProposal/concept/hooks/useConceptGenerator';
import {
  useDeleteConceptsByIds,
  useUpdateConcept,
} from '@ll-platform/frontend/src/creativeProposal/async/useCreativeProposalsMutations';
import type { CreativeProposalConcept } from '@ll-platform/frontend/src/creativeProposal/types';
import {
  assertDefined,
  defined,
  typedEntries,
} from '@ll-platform/frontend/utils/types/types';

import {
  ConceptsSkeletonContent,
  ConceptsSkeletonTitle,
} from './ConceptsSkeleton';

const CONCEPT_TITLE_MAX_LENGTH = 100;
const CONCEPT_CONTENT_MAX_LENGTH = 10000;

const ConceptFormSchema = z.object({
  title: z
    .string({
      // eslint-disable-next-line camelcase
      required_error: 'Title is required',
    })
    .min(1, 'Title is required')
    .max(
      CONCEPT_TITLE_MAX_LENGTH,
      `Title cannot exceed ${CONCEPT_TITLE_MAX_LENGTH} characters`,
    ),
  description: z
    .string({
      // eslint-disable-next-line camelcase
      required_error: 'Content is required',
    })
    .min(1, 'Content is required')
    .max(
      CONCEPT_CONTENT_MAX_LENGTH,
      `Content cannot exceed ${CONCEPT_CONTENT_MAX_LENGTH} characters`,
    ),
});

type ConceptFormSchemaValues = z.infer<typeof ConceptFormSchema>;

type ConceptCardProps = {
  proposalId: string;
  concept: CreativeProposalConcept;
};

export const ConceptCard = ({
  proposalId,
  concept: initialConcept,
}: ConceptCardProps) => {
  const [isRemoving, setIsRemoving] = useState(false);
  const { mutateAsync: mutateDeleteConcept } = useDeleteConceptsByIds();
  const { mutateAsync: mutateUpdateConcept } = useUpdateConcept();

  const { isOpen, onClose, onOpen } = useTwConfirmDialog();

  const handleRemoveClick = async () => {
    onOpen();
  };

  const methods = useForm<ConceptFormSchemaValues>({
    mode: 'onBlur',
    resolver: zodResolver(ConceptFormSchema),
    defaultValues: {
      title: initialConcept.title,
      description: initialConcept.description,
    },
  });

  const updateFormFields = useCallback(
    (
      values: Partial<Pick<ConceptFormSchemaValues, 'title' | 'description'>>,
    ) => {
      typedEntries(values).forEach(([key, value]) => {
        if (defined(value)) {
          methods.setValue(key, value);
        }
      });
    },
    [methods],
  );

  const { isHighlighted, isGenerating } = useConceptGenerator({
    proposalId,
    initialConcept,
    onChange: updateFormFields,
  });

  const handleCancelRemove = () => {
    onClose();
  };

  const handleConfirmRemove = async () => {
    assertDefined(proposalId);
    setIsRemoving(true);

    onClose();
    toast.promise(
      () =>
        mutateDeleteConcept({ id: proposalId, concepts: [initialConcept.id] }),
      {
        error: 'Failed to delete concept',
        loading: 'Deleting concept...',
        success: 'Concept deleted',
      },
    );
  };

  const autoSave = useCallback(async () => {
    if (!(await methods.trigger())) {
      return;
    }

    const values = methods.getValues();
    if (
      values.title === initialConcept.title &&
      values.description === initialConcept.description
    ) {
      return;
    }

    await toast
      .promise(
        () =>
          mutateUpdateConcept({
            proposalId,
            conceptId: initialConcept.id,
            ...values,
          }),
        {
          error: 'Failed to update the concept',
          loading: 'Updating concept...',
          success: 'Concept updated',
          duration: 500,
        },
      )
      .unwrap();
  }, [mutateUpdateConcept, proposalId, methods, initialConcept]);

  const title = methods.watch('title');
  const description = methods.watch('description');

  return (
    <Form {...methods}>
      <form>
        <Card
          className={cn('rounded-lg overflow-hidden p-6 shadow-sm', {
            'bg-card-light border-2 border-white': isHighlighted,
            'opacity-30': isRemoving,
          })}
        >
          <CardContent className="p-0 flex flex-col gap-4">
            {title || (!title && !isGenerating) ? (
              <FormField
                control={methods.control}
                name="title"
                render={() => (
                  <FormItem className="flex flex-col">
                    <div className="flex justify-between gap-4 items-stretch">
                      <Input
                        {...methods.register('title')}
                        className={cn(
                          'w-full space-y-7 py-2 px-3 text-lg/7 font-semibold tracking-normal focus-visible:ring-0 focus-visible:ring-offset-0',
                          methods.formState.errors.title &&
                            'border-red-500 text-red-500',
                        )}
                        onBlur={autoSave}
                        disabled={isGenerating}
                      />
                      <Button
                        variant="ghost"
                        className="px-4"
                        size="lg"
                        type="button"
                        onClick={handleRemoveClick}
                        disabled={isRemoving}
                      >
                        <Trash2 />
                      </Button>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
            ) : (
              <ConceptsSkeletonTitle />
            )}
            {description || (!description && !isGenerating) ? (
              <FormField
                control={methods.control}
                name="description"
                render={() => (
                  <FormItem className="flex flex-col">
                    <TwTextareaAutoresize
                      {...methods.register('description')}
                      id="description"
                      className={cn(
                        'h-[160px] resize-none no-scrollbar focus-visible:ring-0 focus-visible:ring-offset-0',
                        methods.formState.errors.description &&
                          'border-red-500 text-red-500',
                      )}
                      maxLength={CONCEPT_CONTENT_MAX_LENGTH + 1}
                      onBlur={autoSave}
                      disabled={isGenerating}
                      value={description}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            ) : (
              <ConceptsSkeletonContent />
            )}
            {/* TODO: WEB-4387 - Generate Moodboard */}
            {/* {concept.moodboard.length > 0 ? (
              <div className="w-full space-x-4 flex flex-row">
                {concept.moodboard.map((image) => (
                  <div className="w-full h-[125px]" key={image.imageId}>
                    Image placeholder
                  </div>
                ))}
                {Array.from({
                  length: DEFAULT_MOODBOARD_LENGTH - moodboard.length,
                }).map((_, index) => (
                  <ConceptsSkeletonMoodboardImage key={index} />
                ))}
              </div>
            ) : (
              <ConceptsSkeletonMoodboardImages />
            )} */}
          </CardContent>
          <TwConfirmDialog
            open={isOpen}
            onCancel={handleCancelRemove}
            onConfirm={handleConfirmRemove}
            confirmButtonVariant="destructive"
            confirmLabel="Remove concept"
            title="Remove concept?"
            body={
              <div>
                This will remove the selected concept from your list. This
                action cannot be undone.
              </div>
            }
          />
        </Card>
      </form>
    </Form>
  );
};
