import { Sparkles } from 'lucide-react';

import { TwLoadingButton } from '@ll-platform/frontend/components/tw/TwLoadingButton/TwLoadingButton';
import { LOADING_SCOPE_GENERATING } from '@ll-platform/frontend/creativeProposal/concept/consts';
import type { CreativeProposalConcept } from '@ll-platform/frontend/creativeProposal/types';
import { useLoadingContext } from '@ll-platform/frontend/utils/contexts/LoadingContext';

type GenerateConceptsButtonProps = {
  concepts: CreativeProposalConcept[];
  onClick: () => void;
  disabled?: boolean;
};

export const GenerateConceptsButton = ({
  concepts,
  onClick,
  disabled,
}: GenerateConceptsButtonProps) => {
  const { isLoading } = useLoadingContext(LOADING_SCOPE_GENERATING);

  return (
    <TwLoadingButton
      className="!mt-auto w-full"
      onClick={onClick}
      disabled={isLoading || disabled}
      loading={isLoading}
      loadingPosition="start"
    >
      {isLoading ? (
        'Generating...'
      ) : (
        <>
          <Sparkles />
          {concepts.length ? 'Generate more concepts' : 'Generate concepts'}
        </>
      )}
    </TwLoadingButton>
  );
};
