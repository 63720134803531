import { TwBaseProposalLayout } from '@ll-platform/frontend/components/layouts/TwBaseProposalLayout/TwBaseProposalLayout';
import { TwSpinner } from '@ll-platform/frontend/components/tw/TwSpinner/TwSpinner';
import { EnsureDarkTheme } from '@ll-platform/frontend/core/theme/GlobalThemeProvider';
import { useGetCreativeProposalByProposalId } from '@ll-platform/frontend/creativeProposal/async/useCreativeProposalsQueries';
import { CreativeProposalDocumentContent } from '@ll-platform/frontend/creativeProposal/document/CreativeProposalDocumentContent';
import { useCreativeProposalPageParams } from '@ll-platform/frontend/creativeProposal/hooks/useCreativeProposalPageParams';
import type { CreativeProposal } from '@ll-platform/frontend/creativeProposal/types';
import { DocumentBuilderProvider } from '@ll-platform/frontend/documentBuilder/core/builder/DocumentBuilderContext';

import { useCreativeProposalDocumentBuilderConfig } from './useCreativeProposalDocumentBuilderConfig';

const CreativeProposalDocumentInner = ({
  creativeProposal,
}: {
  creativeProposal: CreativeProposal;
}) => {
  const config = useCreativeProposalDocumentBuilderConfig({
    creativeProposal,
  });

  return (
    <DocumentBuilderProvider config={config}>
      <CreativeProposalDocumentContent />
    </DocumentBuilderProvider>
  );
};

export const CreativeProposalDocumentPage = () => {
  const { id } = useCreativeProposalPageParams();

  const { data } = useGetCreativeProposalByProposalId({
    id,
  });

  return (
    <EnsureDarkTheme>
      <TwBaseProposalLayout
        contentContainerProps={{ className: 'overflow-x-auto' }}
      >
        {data ? (
          <CreativeProposalDocumentInner creativeProposal={data} />
        ) : (
          <div className="w-full grid place-items-center">
            <TwSpinner className="w-50 h-50" />
          </div>
        )}
      </TwBaseProposalLayout>
    </EnsureDarkTheme>
  );
};
