import { memo } from 'react';

import {
  DotFilledIcon,
  FontBoldIcon,
  FontItalicIcon,
  TextAlignCenterIcon,
  TextAlignLeftIcon,
  TextAlignRightIcon,
} from '@radix-ui/react-icons';
import { Separator } from '@shadcn/ui/separator';
import { ToggleGroup, ToggleGroupItem } from '@shadcn/ui/toggle-group';
import { Color } from '@tiptap/extension-color';
import { TextAlign } from '@tiptap/extension-text-align';
import { TextStyle } from '@tiptap/extension-text-style';
import {
  BubbleMenu,
  EditorContent,
  useEditor,
  type Editor,
} from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import {
  CircleIcon,
  Heading1Icon,
  Heading2Icon,
  Heading3Icon,
  StrikethroughIcon,
} from 'lucide-react';

import { cn } from '@ll-platform/frontend/components/shadcn/lib/utils';
import type {
  DocumentNodeConfig,
  NodeComponentProps,
} from '@ll-platform/frontend/documentBuilder/core/nodes/nodeTypes';

import {
  RichTextNodeName,
  type RichTextNodeContent,
  type RichTextNodeType,
} from './richTextNodeTypes';

import './text.css';

export const RichTextNode = memo(
  ({ content, onContentChange }: NodeComponentProps<RichTextNodeType>) => {
    const editor = useEditor({
      extensions: [
        StarterKit.configure({
          heading: {
            levels: [1, 2, 3],
          },
        }),
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        TextStyle,
        Color,
      ],
      content: content.text,
      onUpdate: ({ editor }) => {
        onContentChange({
          ...content,
          text: editor.getHTML(),
        });
      },
    });

    return (
      <>
        {editor && <SlidesBubbleMenu editor={editor} />}
        <EditorContent
          editor={editor}
          defaultValue={content.text}
          className="bg-transparent text-black w-full h-full resize-none"
        />
      </>
    );
  },
);
RichTextNode.displayName = 'RichTextNode';

export const RichTextNodeConfig: DocumentNodeConfig<
  typeof RichTextNodeName,
  RichTextNodeContent
> = {
  type: RichTextNodeName,
  render: (props) => <RichTextNode {...props} />,
};

const SlidesBubbleMenu = ({ editor }: { editor: Editor }) => {
  return (
    <BubbleMenu editor={editor} tippyOptions={{ maxWidth: 600 }}>
      <div className="bg-white border rounded-lg shadow-sm p-2 flex flex-row gap-2">
        {/* Heading Controls */}
        <ToggleGroup type="single" variant="outline" size="sm">
          <ToggleGroupItem
            value="h1"
            aria-label="Heading 1"
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 1 }).run()
            }
            data-active={editor.isActive('heading', { level: 1 })}
            className={cn(
              editor.isActive('heading', { level: 1 }) && 'bg-purple-100',
            )}
          >
            <Heading1Icon className="h-4 w-4" />
          </ToggleGroupItem>
          <ToggleGroupItem
            value="h2"
            aria-label="Heading 2"
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 2 }).run()
            }
            data-active={editor.isActive('heading', { level: 2 })}
            className={cn(
              editor.isActive('heading', { level: 2 }) && 'bg-purple-100',
            )}
          >
            <Heading2Icon className="h-4 w-4" />
          </ToggleGroupItem>
          <ToggleGroupItem
            value="h3"
            aria-label="Heading 3"
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 3 }).run()
            }
            data-active={editor.isActive('heading', { level: 3 })}
            className={cn(
              editor.isActive('heading', { level: 3 }) && 'bg-purple-100',
            )}
          >
            <Heading3Icon className="h-4 w-4" />
          </ToggleGroupItem>
        </ToggleGroup>

        <Separator orientation="vertical" />

        {/* Text Alignment */}
        <ToggleGroup type="single" variant="outline" size="sm">
          <ToggleGroupItem
            value="left"
            aria-label="Align Left"
            onClick={() => editor.chain().focus().setTextAlign('left').run()}
            data-active={editor.isActive('textAlign', { textAlign: 'left' })}
            className={cn(
              editor.isActive('textAlign', { textAlign: 'left' }) &&
                'bg-purple-100',
            )}
          >
            <TextAlignLeftIcon className="h-4 w-4" />
          </ToggleGroupItem>
          <ToggleGroupItem
            value="center"
            aria-label="Align Center"
            onClick={() => editor.chain().focus().setTextAlign('center').run()}
            data-active={editor.isActive('textAlign', { textAlign: 'center' })}
            className={cn(
              editor.isActive('textAlign', { textAlign: 'center' }) &&
                'bg-purple-100',
            )}
          >
            <TextAlignCenterIcon className="h-4 w-4" />
          </ToggleGroupItem>
          <ToggleGroupItem
            value="right"
            aria-label="Align Right"
            onClick={() => editor.chain().focus().setTextAlign('right').run()}
            data-active={editor.isActive('textAlign', { textAlign: 'right' })}
            className={cn(
              editor.isActive('textAlign', { textAlign: 'right' }) &&
                'bg-purple-100',
            )}
          >
            <TextAlignRightIcon className="h-4 w-4" />
          </ToggleGroupItem>
        </ToggleGroup>

        <Separator orientation="vertical" />

        {/* Text Formatting */}
        <ToggleGroup type="multiple" variant="outline" size="sm">
          <ToggleGroupItem
            value="bold"
            aria-label="Bold"
            onClick={() => editor.chain().focus().toggleBold().run()}
            data-active={editor.isActive('bold')}
            className={cn(editor.isActive('bold') && 'bg-purple-100')}
          >
            <FontBoldIcon className="h-4 w-4" />
          </ToggleGroupItem>
          <ToggleGroupItem
            value="italic"
            aria-label="Italic"
            onClick={() => editor.chain().focus().toggleItalic().run()}
            data-active={editor.isActive('italic')}
            className={cn(editor.isActive('italic') && 'bg-purple-100')}
          >
            <FontItalicIcon className="h-4 w-4" />
          </ToggleGroupItem>
          <ToggleGroupItem
            value="strike"
            aria-label="Strikethrough"
            onClick={() => editor.chain().focus().toggleStrike().run()}
            data-active={editor.isActive('strike')}
            className={cn(editor.isActive('strike') && 'bg-purple-100')}
          >
            <StrikethroughIcon className="h-4 w-4" />
          </ToggleGroupItem>
        </ToggleGroup>

        <Separator orientation="vertical" />

        {/* Text Color */}
        <ToggleGroup type="single" variant="outline" size="sm">
          <ToggleGroupItem
            value="white"
            aria-label="White Text"
            onClick={() => editor.chain().focus().setColor('#ffffff').run()}
            data-active={editor.isActive('textStyle', { color: '#ffffff' })}
            className={cn(
              editor.isActive('textStyle', { color: '#ffffff' }) &&
                'bg-purple-100',
            )}
          >
            <CircleIcon className="h-4 w-4 text-white stroke-gray-400" />
          </ToggleGroupItem>
          <ToggleGroupItem
            value="black"
            aria-label="Black Text"
            onClick={() => editor.chain().focus().setColor('#000000').run()}
            data-active={editor.isActive('textStyle', { color: '#000000' })}
            className={cn(
              editor.isActive('textStyle', { color: '#000000' }) &&
                'bg-purple-100',
            )}
          >
            <DotFilledIcon className="h-4 w-4" />
          </ToggleGroupItem>
        </ToggleGroup>
      </div>
    </BubbleMenu>
  );
};
