import type { NodeStyleProps } from '@ll-platform/frontend/documentBuilder/core/nodes/nodeTypes';
import {
  DOCUMENT_SLIDE_HEIGHT,
  DOCUMENT_SLIDE_WIDTH,
} from '@ll-platform/frontend/documentBuilder/core/slides/slideConsts';
import { makeSlide } from '@ll-platform/frontend/documentBuilder/core/slides/slideHelpers';
import type { DocumentSlide } from '@ll-platform/frontend/documentBuilder/core/slides/slideTypes';
import type { SlideTemplateConfig } from '@ll-platform/frontend/documentBuilder/core/slideTemplates/slideTemplateTypes';
import {
  makeDefaultImageObject,
  makeMediaNode,
} from '@ll-platform/frontend/documentBuilder/shared/nodes/MediaNode/mediaNodeHelpers';
import { type MediaObject } from '@ll-platform/frontend/documentBuilder/shared/nodes/MediaNode/mediaNodeTypes';
import { makeRichTextNode } from '@ll-platform/frontend/documentBuilder/shared/nodes/RichTextNode/richTextNodeHelpers';

export const makeDocumentTitleSlide = ({
  title,
  media,
}: TitleSlideTemplateParams): DocumentSlide => {
  return makeSlide({
    nodes: [
      makeMediaNode({
        content: {
          media,
        },
        style: backgroundMediaStyle,
      }),
      makeRichTextNode({
        content: {
          text: `<h1 style="text-align: center"><span style="color: #ffffff">${title}</span></h1>`,
        },
        style: titleTextStyle,
      }),
    ],
  });
};

const titleTextStyle: NodeStyleProps = {
  top: '45%',
  left: '10%',
  right: '10%',
};

const backgroundMediaStyle: NodeStyleProps = {
  top: 0,
  left: 0,
  width: DOCUMENT_SLIDE_WIDTH,
  height: DOCUMENT_SLIDE_HEIGHT,
};

export type TitleSlideTemplateParams = {
  title: string;
  media: MediaObject;
};

export const titleSlideTemplateDefaults: TitleSlideTemplateParams = {
  title: 'Title',
  media: makeDefaultImageObject('https://placehold.co/1440x800'),
};

export const titleSlideTemplate: SlideTemplateConfig = {
  name: 'Title',
  create: async () => ({
    slides: [makeDocumentTitleSlide(titleSlideTemplateDefaults)],
  }),
};
