import type { NodeStyleProps } from '@ll-platform/frontend/documentBuilder/core/nodes/nodeTypes';
import { DOCUMENT_SLIDE_MARGIN } from '@ll-platform/frontend/documentBuilder/core/slides/slideConsts';
import { makeSlide } from '@ll-platform/frontend/documentBuilder/core/slides/slideHelpers';
import type { DocumentSlide } from '@ll-platform/frontend/documentBuilder/core/slides/slideTypes';
import type { SlideTemplateConfig } from '@ll-platform/frontend/documentBuilder/core/slideTemplates/slideTemplateTypes';
import {
  makeDefaultImageObject,
  makeMediaNode,
} from '@ll-platform/frontend/documentBuilder/shared/nodes/MediaNode/mediaNodeHelpers';
import { type MediaObject } from '@ll-platform/frontend/documentBuilder/shared/nodes/MediaNode/mediaNodeTypes';
import { makeRichTextNode } from '@ll-platform/frontend/documentBuilder/shared/nodes/RichTextNode/richTextNodeHelpers';

export const makeDocumentOverviewSlide = ({
  overview,
  media,
}: OverviewSlideTemplateParams): DocumentSlide => {
  return makeSlide({
    nodes: [
      makeRichTextNode({
        content: {
          text: overview,
        },
        style: overviewTextStyle,
      }),
      makeMediaNode({
        content: {
          media,
        },
        style: mediaStyle,
      }),
    ],
  });
};

const overviewTextStyle: NodeStyleProps = {
  top: DOCUMENT_SLIDE_MARGIN,
  bottom: DOCUMENT_SLIDE_MARGIN,
  left: DOCUMENT_SLIDE_MARGIN,
  width: '36%',
};

const mediaStyle: NodeStyleProps = {
  top: DOCUMENT_SLIDE_MARGIN,
  bottom: DOCUMENT_SLIDE_MARGIN,
  right: DOCUMENT_SLIDE_MARGIN,
  width: 660,
};

export type OverviewSlideTemplateParams = {
  overview: string;
  media: MediaObject;
};

export const overviewSlideTemplateDefaults: OverviewSlideTemplateParams = {
  overview:
    `Explain in one or two concise sentences how your solution transforms users' challenges into positive outcomes. Focus on the end benefits that matter most to your target audience. Keep it clear and compelling.Explain in one or two concise sentences how your solution transforms users' challenges into positive outcomes. Focus on the end benefits that matter most to your target audience. Keep it clear and compelling.Explain in one or two concise sentences how your solution transforms users' challenges into positive outcomes. Focus on the end benefits that matter most to your target audience. Keep it clear and compelling.Explain in one or two concise sentences how your solution transforms users' challenges into positive outcomes. Focus on the end benefits that matter most to your target audience. Keep it clear and compelling.Explain in one or two concise sentences how your solution transforms users' challenges into positive outcomes. Focus on the end benefits that matter most to your target audience. Keep it clear and compelling.`.trim(),
  media: makeDefaultImageObject('https://placehold.co/660x704'),
};

export const overviewSlideTemplate: SlideTemplateConfig = {
  name: 'Overview',
  create: async () => ({
    slides: [makeDocumentOverviewSlide(overviewSlideTemplateDefaults)],
  }),
};
