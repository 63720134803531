import {
  budgetSlideTemplateDefaults,
  makeDocumentBudgetSlide,
} from '@ll-platform/frontend/creativeProposal/document/slideTemplates/BudgetSlideTemplate/BudgetSlideTemplate';
import {
  makeDocumentOverviewSlide,
  overviewSlideTemplateDefaults,
} from '@ll-platform/frontend/creativeProposal/document/slideTemplates/OverviewSlideTemplate/OverviewSlideTemplate';
import type { CreativeProposal } from '@ll-platform/frontend/creativeProposal/types';
import type { DocumentData } from '@ll-platform/frontend/documentBuilder/core/builder/documentBuilderTypes';
import {
  DOCUMENT_SLIDE_HEIGHT,
  DOCUMENT_SLIDE_WIDTH,
} from '@ll-platform/frontend/documentBuilder/core/slides/slideConsts';
import { makeDefaultImageObject } from '@ll-platform/frontend/documentBuilder/shared/nodes/MediaNode/mediaNodeHelpers';
import { makeDocumentTitleSlide } from '@ll-platform/frontend/documentBuilder/shared/slideTemplates/TitleSlideTemplate/TitleSlideTemplate';

export const createProposalDocument = ({
  creativeProposal,
}: {
  creativeProposal: CreativeProposal;
}): DocumentData => {
  return {
    slides: [
      makeDocumentTitleSlide({
        title: creativeProposal.name,
        media: makeDefaultImageObject(
          `https://picsum.photos/seed/2/${DOCUMENT_SLIDE_WIDTH}/${DOCUMENT_SLIDE_HEIGHT}`,
        ),
      }),
      makeDocumentOverviewSlide(overviewSlideTemplateDefaults),
      makeDocumentBudgetSlide(budgetSlideTemplateDefaults),
      makeDocumentTitleSlide({
        title: 'Thank you!',
        media: makeDefaultImageObject(
          `https://picsum.photos/seed/3/${DOCUMENT_SLIDE_WIDTH}/${DOCUMENT_SLIDE_HEIGHT}`,
        ),
      }),
    ],
    metadata: {},
  };
};
