import { useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { BudgetNodeConfig } from '@ll-platform/frontend/creativeProposal/document/nodes/BudgetNode/BudgetNode';
import { budgetSlideTemplate } from '@ll-platform/frontend/creativeProposal/document/slideTemplates/BudgetSlideTemplate/BudgetSlideTemplate';
import { overviewSlideTemplate } from '@ll-platform/frontend/creativeProposal/document/slideTemplates/OverviewSlideTemplate/OverviewSlideTemplate';
import type { CreativeProposal } from '@ll-platform/frontend/creativeProposal/types';
import {
  type DocumentBuilderConfig,
  type DocumentBuilderViewModeEnum,
} from '@ll-platform/frontend/documentBuilder/core/builder/documentBuilderTypes';
import type { DocumentNodeConfig } from '@ll-platform/frontend/documentBuilder/core/nodes/nodeTypes';
import { defaultDocumentBuilderNodes } from '@ll-platform/frontend/documentBuilder/shared/nodes/consts';
import { titleSlideTemplate } from '@ll-platform/frontend/documentBuilder/shared/slideTemplates/TitleSlideTemplate/TitleSlideTemplate';
import { assertDefined } from '@ll-platform/frontend/utils/types/types';

export const useCreativeProposalDocumentBuilderConfig = ({
  creativeProposal,
}: {
  creativeProposal: CreativeProposal;
}): DocumentBuilderConfig => {
  const { mode } = useParams<{ mode: DocumentBuilderViewModeEnum }>();
  assertDefined(mode, 'mode path param');

  return useMemo((): DocumentBuilderConfig => {
    return {
      documentId: creativeProposal.id,
      mode,
      nodes: [
        ...defaultDocumentBuilderNodes,
        BudgetNodeConfig,
      ] as DocumentNodeConfig[],
      slideTemplates: [
        titleSlideTemplate,
        overviewSlideTemplate,
        budgetSlideTemplate,
      ],
    };
  }, [creativeProposal.id, mode]);
};
