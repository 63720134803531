import { useEffect, useMemo, useState } from 'react';

import type {
  FileValidators,
  OutputFileStatus,
} from '@uploadcare/react-uploader';
import { Upload } from 'lucide-react';
import { useParams } from 'react-router-dom';

import { Button } from '@ll-platform/frontend/components/shadcn/ui/button';
import {
  Dialog,
  DialogContent,
} from '@ll-platform/frontend/components/shadcn/ui/dialog';
import {
  TwUploadcareWidget,
  type OutputFileEntry,
} from '@ll-platform/frontend/components/UploadcareWidget/TwUploadcareWidget';
import { useAddProposalFile } from '@ll-platform/frontend/creativeProposal/async/useCreativeProposalsMutations';
import { useGetCreativeProposalByProposalId } from '@ll-platform/frontend/creativeProposal/async/useCreativeProposalsQueries';
import type { ContextFile } from '@ll-platform/frontend/src/creativeProposal/types';
import { assertDefined } from '@ll-platform/frontend/utils/types/types';

import { ConceptAssetsUploaderFile } from './ConceptAssetsUploaderFile';

const MAX_ASSET_SIZE = 50 * 1000 * 1000;

export const ConceptAssetsUploader = ({
  allowedFormats,
}: {
  allowedFormats: string[];
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { id: proposalId } = useParams<{ id: string }>();
  const { data } = useGetCreativeProposalByProposalId({
    id: proposalId ?? '',
  });
  const { mutateAsync: mutateAddProposalFile } = useAddProposalFile();

  const [uploadingFiles, setUploadingFiles] = useState<ContextFile[]>([]);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const savedFiles = useMemo((): ContextFile[] => {
    return (
      data?.fileStore.files.map((f) => ({
        id: f.externalFileId,
        fileName: f.filename,
        fileType: f.mimeType,
        url: f.fileUrl,
      })) ?? []
    );
  }, [data]);

  const handleUpload = (files: OutputFileEntry[] | null) => {
    if (files) {
      assertDefined(
        proposalId,
        'ProposalId should not be undefined in ConceptAssetsUploader.',
      );

      files.forEach(async (file) => {
        const tempFile: ContextFile = {
          id: file.uuid ?? '',
          fileName: file.name,
          fileType: file.mimeType || 'application/octet-stream',
          url: file.cdnUrl || '',
        };

        setUploadingFiles((prev) => [...prev, tempFile]);

        try {
          await mutateAddProposalFile({
            id: proposalId,
            fileUrl: file.cdnUrl!,
            fileName: file.name,
            mimeType: file.mimeType,
          });
        } catch {
          setUploadingFiles((prev) => prev.filter((f) => f.id !== file.uuid));
        }
      });
    }
  };

  useEffect(() => {
    const filterUcFiles = (prev: ContextFile[]) =>
      prev.filter(
        (f) =>
          !data?.fileStore.files.some(
            (fileStoreFile) => fileStoreFile.fileUrl === f.url,
          ),
      );

    setUploadingFiles(filterUcFiles);
  }, [data?.fileStore.files]);

  return (
    <>
      {(!!savedFiles.length || !!uploadingFiles.length) && (
        <div className="flex flex-col gap-1.5 p-1.5 mb-3 border border-dashed border-border shadow-md rounded-md">
          {savedFiles.map((file) => (
            <ConceptAssetsUploaderFile key={file.id} file={file} />
          ))}
          {uploadingFiles.map((file) => (
            <ConceptAssetsUploaderFile key={file.id} file={file} isLoading />
          ))}
        </div>
      )}
      <Button
        onClick={openDialog}
        variant="secondary"
        size="lg"
        className="w-full border border-border"
        type="button"
      >
        <Upload />
        Upload files
      </Button>
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="p-0 overflow-hidden w-auto">
          {isDialogOpen && (
            <TwUploadcareWidget
              onChange={handleUpload}
              multiple
              maxLocalFileSizeBytes={MAX_ASSET_SIZE}
              closeDialog={closeDialog}
              imgOnly={false}
              fileValidators={getFileValidators(allowedFormats)}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

const getFileValidators: (v: string[]) => FileValidators = (
  allowedFormats: string[],
) => [
  (outputEntry: OutputFileEntry<OutputFileStatus>) => {
    const formatsFileExtensions = allowedFormats.map((f) => f.toLowerCase());

    if (
      !formatsFileExtensions.some(
        (ext) => outputEntry.name.split('.').at(-1)?.toLowerCase() === ext,
      )
    ) {
      return {
        message: `Only following formats are allowed: ${allowedFormats.join(', ')}`,
      };
    }

    return undefined;
  },
];
