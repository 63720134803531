import { makeNode } from '@ll-platform/frontend/documentBuilder/core/nodes/nodeHelpers';
import { generateId } from '@ll-platform/frontend/utils/helpers/id';

import {
  MediaNodeName,
  type MediaNodeType,
  type MediaObject,
} from './mediaNodeTypes';

export function makeMediaNode({
  content,
  style,
}: Pick<MediaNodeType, 'content' | 'style'>) {
  return makeNode({
    type: MediaNodeName,
    content,
    style,
  });
}

export function makeDefaultImageObject(url: string): MediaObject {
  return {
    id: generateId(),
    url,
    type: 'image',
    provider: 'file',
    history: [],
    metadata: {
      source: 'default',
    },
  };
}
