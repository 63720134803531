import { useCallback } from 'react';

import type { DocumentNode } from '@ll-platform/frontend/documentBuilder/core/nodes/nodeTypes';
import { useDocumentSlideContext } from '@ll-platform/frontend/documentBuilder/core/slides/DocumentSlideContext';
import { useManageSlides } from '@ll-platform/frontend/documentBuilder/core/slides/useManageSlides';

export function useManageNode(node: DocumentNode) {
  const { slide } = useDocumentSlideContext();
  const { updateSlide } = useManageSlides();

  const updateNode = useCallback(
    (updater: (current: DocumentNode) => DocumentNode) => {
      updateSlide(slide.id, (current) => ({
        ...current,
        nodes: current.nodes.map((n) => (n.id === node.id ? updater(n) : n)),
      }));
    },
    [updateSlide, slide.id, node],
  );

  const updateNodeAttributes = useCallback(
    (attributes: Pick<DocumentNode, 'style'>) => {
      updateNode((current) => ({
        ...current,
        ...attributes,
      }));
    },
    [updateNode],
  );

  const updateNodeContent = useCallback(
    (content: DocumentNode['content']) => {
      updateNode((current) => ({
        ...current,
        content,
      }));
    },
    [updateNode],
  );

  return {
    updateNode,
    updateNodeContent,
    updateNodeAttributes,
  };
}
