import { memo } from 'react';

import { NodeContainer } from '@ll-platform/frontend/documentBuilder/core/nodes/NodeContainer';
import { useDocumentSlideContext } from '@ll-platform/frontend/documentBuilder/core/slides/DocumentSlideContext';
import {
  DOCUMENT_SLIDE_HEIGHT,
  DOCUMENT_SLIDE_WIDTH,
} from '@ll-platform/frontend/documentBuilder/core/slides/slideConsts';

export const DocumentSlide = memo(() => {
  const { slide } = useDocumentSlideContext();

  return (
    <section
      className="relative bg-white text-black overflow-hidden"
      style={{
        minHeight: DOCUMENT_SLIDE_HEIGHT,
        maxHeight: DOCUMENT_SLIDE_HEIGHT,
        width: DOCUMENT_SLIDE_WIDTH,
        ...slide.style,
      }}
    >
      {slide.nodes.map((node) => (
        <NodeContainer key={node.id} node={node} />
      ))}
    </section>
  );
});
DocumentSlide.displayName = 'DocumentSlide';
