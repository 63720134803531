import { useEffect } from 'react';

import { useGetCreativeProposalByProposalId } from '@ll-platform/frontend/creativeProposal/async/useCreativeProposalsQueries';
import { createProposalDocument } from '@ll-platform/frontend/creativeProposal/document/createProposalDocument';
import { useCreativeProposalPageParams } from '@ll-platform/frontend/creativeProposal/hooks/useCreativeProposalPageParams';
import { DocumentBuilderContent } from '@ll-platform/frontend/documentBuilder/core/builder/DocumentBuilderContent';
import {
  useDocumentBuilder,
  useDocumentData,
} from '@ll-platform/frontend/documentBuilder/core/builder/DocumentBuilderContext';

export const CreativeProposalDocumentContent = () => {
  const { id } = useCreativeProposalPageParams();
  const { data: creativeProposal } = useGetCreativeProposalByProposalId({
    id,
  });
  const { updateDocument, isLoading } = useDocumentBuilder();
  const document = useDocumentData();

  useEffect(() => {
    if (!isLoading && !document.slides?.length && creativeProposal) {
      // TODO: WEB-4454 Create base template
      updateDocument(() => createProposalDocument({ creativeProposal }));
    }
  }, [isLoading, document, updateDocument, creativeProposal]);

  return <DocumentBuilderContent />;
};
