import { memo, useCallback } from 'react';

import { Trash2 } from 'lucide-react';

import { Button } from '@ll-platform/frontend/components/shadcn/ui/button';
import { useDocumentData } from '@ll-platform/frontend/documentBuilder/core/builder/DocumentBuilderContext';
import { useDocumentSlideContext } from '@ll-platform/frontend/documentBuilder/core/slides/DocumentSlideContext';
import { useManageSlides } from '@ll-platform/frontend/documentBuilder/core/slides/useManageSlides';

export const TopSlideActionsBar = memo(() => {
  const { deleteSlide } = useManageSlides();
  const document = useDocumentData();
  const { slide, slideIndex } = useDocumentSlideContext();

  const handleDeleteSlide = useCallback(() => {
    deleteSlide(slide.id);
  }, [deleteSlide, slide.id]);

  return (
    <div className="flex justify-between items-center gap-2 py-2 px-4">
      <div className="flex gap-2">Page {slideIndex + 1}</div>
      <Button
        variant="outline"
        onClick={handleDeleteSlide}
        className="w-11"
        disabled={document.slides.length < 2}
      >
        <Trash2 className="h-4 w-4" />
      </Button>
    </div>
  );
});
TopSlideActionsBar.displayName = 'TopSlideActionsBar';
