import { memo } from 'react';

import { useDocumentData } from '@ll-platform/frontend/documentBuilder/core/builder/DocumentBuilderContext';
import { DocumentSlideContainer } from '@ll-platform/frontend/documentBuilder/core/slides/DocumentSlideContainer';
import { DocumentSlideProvider } from '@ll-platform/frontend/documentBuilder/core/slides/DocumentSlideContext';

export const DocumentBuilderContent = memo(() => {
  const document = useDocumentData();

  return (
    <div className="mx-auto flex flex-col gap-2 items-center px-6 pt-6 pb-20">
      {document.slides.map((slide, index) => (
        <DocumentSlideProvider key={slide.id} slide={slide} slideIndex={index}>
          <DocumentSlideContainer />
        </DocumentSlideProvider>
      ))}
    </div>
  );
});
DocumentBuilderContent.displayName = 'DocumentBuilderContent';
