import type { SetOptional } from 'type-fest';

import type { DocumentSlide } from '@ll-platform/frontend/documentBuilder/core/slides/slideTypes';
import { generateId } from '@ll-platform/frontend/utils/helpers/id';

export const makeSlideId = () => `slide-${generateId()}`;

export function makeSlide({
  nodes,
  style = {},
}: SetOptional<
  Pick<DocumentSlide, 'nodes' | 'style'>,
  'style'
>): DocumentSlide {
  return {
    id: makeSlideId(),
    nodes,
    style,
  };
}
