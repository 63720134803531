import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  type ReactNode,
} from 'react';

import { useUpdateEffect } from 'react-use';

import { assertDefined } from '@ll-platform/frontend/utils/types/types';

import type {
  DocumentBuilderConfig,
  DocumentBuilderContextType,
  DocumentData,
} from './documentBuilderTypes';

const DocumentBuilderContext = createContext<DocumentBuilderContextType | null>(
  null,
);
DocumentBuilderContext.displayName = 'DocumentBuilderContext';

const DocumentDataContext = createContext<DocumentData | null>(null);
DocumentDataContext.displayName = 'DocumentDataContext';

const defaultDocumentData: DocumentData = {
  slides: [],
  metadata: {},
};

export const DocumentBuilderProvider = ({
  children,
  config,
}: {
  children: ReactNode;
  config: DocumentBuilderConfig;
}) => {
  const [document, setDocument] = useState<DocumentData>(() => {
    // TODO: WEB-4614 integrate with backend store
    const document = localStorage.getItem(`document-${config.documentId}`);

    if (document) {
      return JSON.parse(document);
    }

    return defaultDocumentData;
  });

  const saveDocument = useCallback(() => {
    // TODO: WEB-4614 integrate with backend store
    console.log('Save document:', document);
    localStorage.setItem(
      `document-${config.documentId}`,
      JSON.stringify(document),
    );
    config.onSave?.(document);
  }, [config, document]);

  const triggerSave = useCallback(() => {
    saveDocument();
  }, [saveDocument]);

  const updateDocument = useCallback(
    (updater: (doc: DocumentData) => DocumentData) => {
      setDocument((current) => {
        const newDocument = updater(current);
        config.onUpdate?.(newDocument);

        return newDocument;
      });
    },
    [config],
  );

  useUpdateEffect(() => {
    // TODO: Debounce
    triggerSave();
  }, [document]);

  const value = useMemo((): DocumentBuilderContextType => {
    return {
      documentId: config.documentId,
      mode: config.mode,
      availableNodes: config.nodes,
      slideTemplates: config.slideTemplates,
      isLoading: false,
      triggerSave,
      updateDocument,
    };
  }, [config, updateDocument, triggerSave]);

  return (
    <DocumentBuilderContext.Provider value={value}>
      <DocumentDataContext.Provider value={document}>
        {children}
      </DocumentDataContext.Provider>
    </DocumentBuilderContext.Provider>
  );
};

export const useDocumentBuilder = () => {
  const context = useContext(DocumentBuilderContext);
  assertDefined(context, 'DocumentBuilderContext in useDocumentBuilder');

  return context;
};

export const useDocumentData = () => {
  const context = useContext(DocumentDataContext);
  assertDefined(context, 'DocumentDataContext in useDocumentData');

  return context;
};
