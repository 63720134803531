import { makeBudgetNode } from '@ll-platform/frontend/creativeProposal/document/nodes/BudgetNode/budgetNodeHelpers';
import type { NodeStyleProps } from '@ll-platform/frontend/documentBuilder/core/nodes/nodeTypes';
import { DOCUMENT_SLIDE_MARGIN } from '@ll-platform/frontend/documentBuilder/core/slides/slideConsts';
import { makeSlide } from '@ll-platform/frontend/documentBuilder/core/slides/slideHelpers';
import type { DocumentSlide } from '@ll-platform/frontend/documentBuilder/core/slides/slideTypes';
import type { SlideTemplateConfig } from '@ll-platform/frontend/documentBuilder/core/slideTemplates/slideTemplateTypes';
import { makeRichTextNode } from '@ll-platform/frontend/documentBuilder/shared/nodes/RichTextNode/richTextNodeHelpers';

export const makeDocumentBudgetSlide = ({
  text,
  totalBudget,
}: BudgetSlideTemplateParams): DocumentSlide => {
  return makeSlide({
    nodes: [
      makeBudgetNode({
        content: {
          totalBudget,
        },
      }),
      makeRichTextNode({
        content: {
          text,
        },
        style: budgetTextStyle,
      }),
    ],
  });
};

const budgetTextStyle: NodeStyleProps = {
  top: DOCUMENT_SLIDE_MARGIN,
  left: DOCUMENT_SLIDE_MARGIN,
  width: '36%',
  height: '50%',
};

export type BudgetSlideTemplateParams = {
  text: string;
  totalBudget: number;
};

export const budgetSlideTemplateDefaults: BudgetSlideTemplateParams = {
  text: `
Budget

Quis ipsum occaecat veniam minim culpa proident ipsum labore commodo ex ad culpa magna occaecat. Duis enim ipsum ex deserunt cillum. Adipisicing labore ad commodo consectetur ex occaecat enim proident labore velit tempor officia. Mollit pariatur sunt dolor occaecat eiusmod. Incididunt qui aliqua incididunt exercitation amet incididunt. In duis ullamco reprehenderit sint nostrud tempor elit. Et tempor laboris nostrud esse qui nisi qui aliqua eiusmod id mollit.
`.trim(),
  totalBudget: 10000,
};

export const budgetSlideTemplate: SlideTemplateConfig = {
  name: 'Budget',
  create: async () => ({
    slides: [makeDocumentBudgetSlide(budgetSlideTemplateDefaults)],
  }),
};
