import { memo } from 'react';

import type {
  DocumentNodeConfig,
  NodeComponentProps,
} from '@ll-platform/frontend/documentBuilder/core/nodes/nodeTypes';
import { makeDefaultImageObject } from '@ll-platform/frontend/documentBuilder/shared/nodes/MediaNode/mediaNodeHelpers';
import {
  MediaNodeName,
  type MediaNodeContent,
  type MediaNodeType,
} from '@ll-platform/frontend/documentBuilder/shared/nodes/MediaNode/mediaNodeTypes';

export const MediaNode = memo(
  ({ content, onContentChange }: NodeComponentProps<MediaNodeType>) => {
    // TODO: Support other media types
    return (
      <img
        src={content.media.url}
        onClick={() =>
          onContentChange({
            ...content,
            media: makeDefaultImageObject(
              window.prompt('Enter image URL:') || content.media.url,
            ),
          })
        }
      />
    );
  },
);
MediaNode.displayName = 'MediaNode';

export const MediaNodeConfig: DocumentNodeConfig<
  typeof MediaNodeName,
  MediaNodeContent
> = {
  type: MediaNodeName,
  render: (props) => <MediaNode {...props} />,
};
