import { Outlet, type RouteObject } from 'react-router-dom';

import { TwBaseProposalLayout } from '@ll-platform/frontend/components/layouts/TwBaseProposalLayout/TwBaseProposalLayout';
import {
  lazyLoad,
  withLoader,
} from '@ll-platform/frontend/core/router/helpers';
import { EnsureDarkTheme } from '@ll-platform/frontend/core/theme/GlobalThemeProvider';
import { NewConceptPage } from '@ll-platform/frontend/creativeProposal/concept/pages/NewConceptPage/NewConceptPage';
import {
  IsInternalLoggedInGuard,
  IsLoggedInGuard,
} from '@ll-platform/frontend/features/auth/guards/IsLoggedInGuard';
import { InternalDashboardLayout } from '@ll-platform/frontend/features/internalDashboard/components/layout/InternalDashboardLayout';

import { CreativeProposalDocumentPage } from './document/CreativeProposalDocumentPage';
import { NewCreativeProposalFeatureFlagGuard } from './NewCreativeProposalFeatureFlagGuard';

const CreativeProposalsPage = withLoader(
  lazyLoad(() => import('./dashboard/pages/CreativeProposalsPage'))
    .CreativeProposalsPage,
);
const CreativeProposalController = withLoader(
  lazyLoad(() => import('./CreativeProposalController'))
    .CreativeProposalController,
);

export const creativeProposalRoutes: RouteObject[] = [
  {
    path: 'creative-proposal',
    element: (
      <NewCreativeProposalFeatureFlagGuard>
        <EnsureDarkTheme>
          <Outlet />
        </EnsureDarkTheme>
      </NewCreativeProposalFeatureFlagGuard>
    ),
    children: [
      {
        path: 'dashboard',
        element: (
          <IsInternalLoggedInGuard>
            <Outlet />
          </IsInternalLoggedInGuard>
        ),
        children: [
          {
            path: '',
            element: (
              <InternalDashboardLayout>
                <CreativeProposalsPage />
              </InternalDashboardLayout>
            ),
          },
        ],
      },
      {
        path: ':id',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: (
              <IsInternalLoggedInGuard>
                <TwBaseProposalLayout>
                  <CreativeProposalController />
                </TwBaseProposalLayout>
              </IsInternalLoggedInGuard>
            ),
          },
          // This route will be moved to dialog
          {
            path: 'concepts',
            element: (
              <IsInternalLoggedInGuard>
                <TwBaseProposalLayout>
                  <NewConceptPage />
                </TwBaseProposalLayout>
              </IsInternalLoggedInGuard>
            ),
          },
          {
            path: 'doc/:mode',
            element: (
              <IsLoggedInGuard>
                <CreativeProposalDocumentPage />
              </IsLoggedInGuard>
            ),
          },
        ],
      },
    ],
  },
];
