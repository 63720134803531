import { useEffect } from 'react';

import { PanelLeftClose, PanelLeftOpen } from 'lucide-react';

import { TwTypography } from '@ll-platform/frontend/components/tw/TwTypography/TwTypography';
import { LOADING_SCOPE_GENERATING } from '@ll-platform/frontend/creativeProposal/concept/consts';
import { cn } from '@ll-platform/frontend/src/components/shadcn/lib/utils';
import { Button } from '@ll-platform/frontend/src/components/shadcn/ui/button';
import {
  Sidebar,
  SidebarContent,
  SidebarHeader,
  useSidebar,
} from '@ll-platform/frontend/src/components/shadcn/ui/sidebar';
import { useLoadingContext } from '@ll-platform/frontend/utils/contexts/LoadingContext';

import { ConceptForm } from './ConceptForm';

type ConceptSidebarProps = {
  //
};

// eslint-disable-next-line no-empty-pattern
export const ConceptSidebar = ({}: ConceptSidebarProps) => {
  const { open, toggleSidebar, setOpen } = useSidebar();
  const loadingGeneratingCtx = useLoadingContext(LOADING_SCOPE_GENERATING);

  useEffect(() => {
    if (loadingGeneratingCtx.isLoading) {
      setOpen(false);
    }
    // Only when started loading
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingGeneratingCtx.isLoading]);

  return (
    <Sidebar
      variant="sidebar"
      className="mt-16 max-h-[calc(100%_-_64px)] px-0 pt-0"
      collapsible="icon"
    >
      <SidebarHeader className="flex flex-col">
        <Button
          className={open ? 'self-end' : 'self-center'}
          variant="outline"
          size={'sm'}
          onClick={toggleSidebar}
        >
          {open ? <PanelLeftClose /> : <PanelLeftOpen />}
        </Button>
      </SidebarHeader>
      <SidebarContent
        className={cn(
          'flex-col gap-2 px-0',
          'transition-all duration-100 ease-linear',
          open ? 'translate-x-0' : '-translate-x-full delay-100',
        )}
      >
        <div className="px-6">
          <TwTypography variant="large">Generate concepts</TwTypography>
          <TwTypography variant="muted">
            Provide details to generate video concepts.
          </TwTypography>
        </div>
        <ConceptForm />
      </SidebarContent>
    </Sidebar>
  );
};
